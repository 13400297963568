import { clsx } from 'clsx';
import { Stack } from 'geist/components';
import type { JSX } from 'react';
import styles from './preview-card.module.css';

interface PreviewCardProps {
  /**
   * The title of the context card.
   */
  title: string;
  /**
   * The content of the context card. Can be a string, or a custom element.
   */
  children: React.ReactNode;
  /**
   * A custom icon to display in the context card.
   */
  customIcon?: React.ReactNode;
}

/**
 * The preview card that opens when hovering over the ContextLink.
 * Shows information about the link.
 */
export function PreviewCard({
  children,
  title,
  customIcon,
}: PreviewCardProps): JSX.Element {
  return (
    <div className={clsx(styles.card, 'geist-hide-on-mobile')}>
      <Stack direction="row" gap={1}>
        <h4>{title}</h4>
        {customIcon}
      </Stack>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
