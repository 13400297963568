import { Flag as IconFlag, UserCheck as IconUserCheck } from 'geist/icons';
import { Text, Link } from 'geist/components';
import type { JSX } from 'react';

interface PermissionsCalloutProps {
  isEnt?: boolean;
  isPro?: boolean;
  isHobby?: boolean;
  isBeta?: boolean;
  isLimitedBeta?: boolean;
  isPrivateBeta?: boolean;
  feature: string;
  roles?: string[];
  isPlural?: boolean;
  suffix?: string;
  compact?: boolean;
  roleAccessText?: string;
  entAddOn?: boolean;
}

export function PermissionsCallout({
  isEnt,
  isPro,
  isBeta,
  isHobby,
  isLimitedBeta,
  isPrivateBeta,
  feature,
  isPlural,
  roles,
  suffix,
  compact,
  roleAccessText,
  entAddOn,
}: PermissionsCalloutProps): JSX.Element | null {
  function featureOnProAndEntPlans(): JSX.Element {
    const permList: string[] = [];
    if (isPrivateBeta) {
      permList.push('Enterprise');
    } else {
      if (isEnt) permList.push('Enterprise');
      if (isPro) permList.push('Pro');
      if (isHobby) permList.push('Hobby');
    }

    const getPlanTypes = (): string => {
      if (permList.length === 0 || permList.length === 3) return 'all';

      return permList.reduce((permString, perm, count): string => {
        let conjunction = ' ';

        count <= permList.length - 1
          ? (conjunction = ' and ')
          : (conjunction = ', ');

        return perm + conjunction + permString;
      });
    };

    let pricingPage = '';

    if (isPrivateBeta || isEnt) pricingPage = 'enterprise';
    else if (isPro) pricingPage = 'pro';

    return (
      <Link href={`/docs/accounts/plans/${pricingPage}`} variant="highlight">
        {getPlanTypes()} plans
      </Link>
    );
  }

  // Allow manual overriding of whether the feature is a pluralised or not.
  // Useful if the feature ends with an 's' but is not pluralised.
  const usePlural = isPlural !== undefined ? isPlural : feature.endsWith('s');

  const betaText = isBeta && (
    <Link href="/docs/release-phases#beta" variant="highlight">
      Beta
    </Link>
  );

  const limitedBetaText = isLimitedBeta && (
    <Link href="/docs/release-phases#limited-beta" variant="highlight">
      Limited Beta
    </Link>
  );

  const privateBetaText = isPrivateBeta && (
    <Link href="/docs/release-phases#private-beta" variant="highlight">
      Private Beta
    </Link>
  );

  const rolesArray = roles || [];
  const roleCount = rolesArray.length;

  const moreThanOneRole = roleCount > 1;
  const moreThanTwoRoles = roleCount > 2;
  const lastRoleIndex = roleCount - 2;
  const roleText = moreThanOneRole ? 'roles' : 'role';

  return (
    <div
      className={`container relative ${compact ? 'mb-[1rem]' : 'mb-[2rem]'} mt-[1rem] flex w-full flex-col justify-evenly gap-[0.5rem] overflow-hidden rounded-[8px] border-[1px] border-solid border-[#eaeaea] bg-[var(--geist-background)] p-[20px] dark:border-[var(--accents-2)]`}
    >
      <div className="flex w-full items-center gap-[1rem]">
        <IconFlag
          className="shrink-0 text-[var(--accents-5)]"
          color="var(--accents-5)"
          size={16}
        />
        <Text as="p" color="gray-1000" lineHeight={20} size={16}>
          {feature} {usePlural ? 'are' : 'is'} available{' '}
          {entAddOn ? 'for purchase' : ''}{' '}
          {isBeta || isPrivateBeta || isLimitedBeta ? (
            <span>in {betaText}</span>
          ) : null}
          {isLimitedBeta ? <span> {limitedBetaText}</span> : null}{' '}
          {isPrivateBeta ? <span> {privateBetaText}</span> : null}{' '}
          {isPrivateBeta || isLimitedBeta ? 'to' : 'on'}{' '}
          {featureOnProAndEntPlans()}
          {suffix ? <span> {suffix}</span> : null}
        </Text>
      </div>
      {rolesArray.length ? (
        <div className="flex w-full items-center gap-[1rem]">
          <IconUserCheck
            className="shrink-0 text-[var(--accents-5)]"
            color="var(--accent-5)"
            size={16}
          />
          <Text as="p" color="gray-1000" size={16}>
            Those with the{' '}
            {rolesArray.map((role, i) => (
              <span key={role}>
                <Link
                  href="/docs/teams-and-accounts/team-members-and-roles#managing-team-roles"
                  variant="highlight"
                >
                  {role}
                </Link>
                {moreThanTwoRoles && i !== lastRoleIndex
                  ? i < lastRoleIndex && <>{', '}</>
                  : null}
                {i === lastRoleIndex && <>{' and '}</>}
              </span>
            ))}{' '}
            {roleText} {roleAccessText || 'can access this feature'}
          </Text>
        </div>
      ) : null}
    </div>
  );
}
