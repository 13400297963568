import * as HoverCard from '@radix-ui/react-hover-card';
import { BookOpen as IconBookOpen } from 'geist/icons';
import type { JSX } from 'react';
import { Link } from '@pyra/multi-zone/link';
import { PreviewCard } from './preview-card';
import styles from './preview-card.module.css';

interface ContextLinkProps {
  /**
   * The content of the context card. Can be a string, or a custom element.
   */
  children: React.ReactNode;
  /**
   * The href of the link.
   */
  href?: string;
  /**
   * The title of the context card.
   */
  title: string;
  /**
   * The text displayed in the link.
   */
  linkText: string;
  /**
   * The icon displayed next to the link text.
   */
  icon?: React.ReactNode;
  /**
   * A custom icon to display in the context card.
   */
  customIcon?: React.ReactNode;
}

/**
 * A link that opens a context card when hovered.
 * The link shows a small open book icon to indicate that it offers more information about the link.
 */
export function ContextLink({
  children,
  href,
  title,
  linkText,
  icon,
  customIcon,
}: ContextLinkProps): JSX.Element {
  return (
    <HoverCard.Root closeDelay={0} openDelay={300}>
      <HoverCard.Trigger asChild>
        <Link href={href} variant="highlight">
          {linkText}
          {icon ? (
            icon
          ) : (
            <IconBookOpen className={styles.icon} size="0.8em" weight="bold" />
          )}
        </Link>
      </HoverCard.Trigger>
      <HoverCard.Content align="center" className={styles.hoverCard} side="top">
        <PreviewCard customIcon={customIcon} title={title}>
          {children}
        </PreviewCard>
        <HoverCard.Arrow className={styles.arrow} />
      </HoverCard.Content>
    </HoverCard.Root>
  );
}
